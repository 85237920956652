<script>
export default {
  name: "CommentNotice",
  data() {
    return {
      comment: '',
      isActive: 'n',
      speed: 1,
    }
  },
  computed: {
    isActiveNotice(){
      return this.isActive === 'y'
    }
  },
  watch:{
    comment(){
      this.adjustSpeed();
    }
  },
  methods:{
    onNotice(data){
      this.comment = data.comment;
      this.isActive = data.isActive;
    },
    adjustSpeed() {
      // 브라우저 너비에 따라 속도를 계산 (뷰포트 너비에 비례)
      const viewportWidth = window.innerWidth;
      const isLowLength = this.comment.length < 150;
      const standardCommentLength = Math.round(viewportWidth / 10);
      const commentLength = this.comment.length;
      let adjustValue = 0;
      let calcLength = 0;
      let speedFactor = 0;
      let calculatedSpeed = 0;

      //내용이 길때 아닐때 다르게 조정해줘야함
      if( isLowLength ){
        adjustValue = 5;
        calcLength = viewportWidth > 1000 ? 0.3 : 0.5;
        speedFactor = calcLength * adjustValue;
      }else{
        adjustValue = 10;
        calcLength = Math.round(commentLength / standardCommentLength);
        speedFactor = calcLength * adjustValue; //adjustValue 를 곱해줌으로써 속도조절
      }

      calculatedSpeed = Math.round(viewportWidth / commentLength) * speedFactor;
      this.speed = `${calculatedSpeed}s`;
      // console.log('CommentNotice');
      // console.log(Math.round(viewportWidth / commentLength));
      // console.log(`viewportWidth: ${viewportWidth} / standardCommentLength: ${standardCommentLength} / commentLength: ${commentLength} / speedFactor: ${speedFactor}`);
      // console.log(`calcLength: ${calcLength} / adjustValue: ${adjustValue} / calculatedSpeed: ${calculatedSpeed}`);
    }
  },
  created() {
    this.EventBus.$on(`common-commentNotice`, this.onNotice);
  },
  mounted() {
    window.addEventListener('resize', this.adjustSpeed);
    window.addEventListener('load', this.adjustSpeed);
  },
  destroyed() {
    window.removeEventListener('resize', this.adjustSpeed);
    window.removeEventListener('load', this.adjustSpeed);
  }
}
</script>

<template>
  <div class="wrap-notice" v-if="isActiveNotice">
    <div class="notice">
      <div class="ico"><img src="@/assets/v2/svg/ico_alert.svg" alt="" width="18"></div>
      <div class="wrap-txt">
        <div class="marquee">
          <span :style="{animationDuration: speed}">
            {{ comment }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>